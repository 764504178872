export const isUppercase = (password) => {
  let count = 0
  for (let char of password) {
    if (char === char.toUpperCase() && /^[a-zA-Z]+$/.test(char)) {
      count++
      if (count === 1) return true
    }
  }
  return false
}

export const hasSymbol = (password) => {
  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  let count = 0
  for (let char of password) {
    if (format.test(char)) {
      count++
      if (count === 1) return true
    }
  }
  return false
}

export const binarySearch = (userChannels, channel, start, end) => { 
  if (userChannels.length === 0) return false
  if (start > end) return false
  let mid = Math.floor((start + end)/2)
  if (userChannels[mid] === channel) return true
  if(userChannels[mid] > channel) {  
    return binarySearch(userChannels, channel, start, mid-1); 
  }
  else {
    return binarySearch(userChannels, channel, mid+1, end); 
  }
}

export const validate = (password, minLen) => { 
  return {
    passwordLength: !(password.length >= minLen),
    isUpperCase: !isUppercase(password),
    hasSymbol: !hasSymbol(password),
    hasNumber: !/\d/.test(password),
    all: !((password.length >= minLen) && isUppercase(password) && hasSymbol(password) && /\d/.test(password)) ? true : false
  }
}

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase())
}

export const isValidID = ({id, minLen, companyId=false}={}) => {
  if (id.length !== minLen) {
    return false
  }
  return (/^\d{10}$/.test(id) && companyId)
}

export const isValidContactID = ({id, contactId=false, minLen}={}) => {
  if (!(id.length >= minLen)) {
    return false
  }
  return (/^\d{6,7}$/.test(id))
}