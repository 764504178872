import { replace, push } from 'connected-react-router'

export const sortParse = (field) => {
  const sort = {}
  
  if (field) {
    if (field.startsWith('-')) {
      sort.direction = -1
      sort.field = field.replace('-', '')
    } else {
      sort.direction = 1
      sort.field = field
    }
  }

  return sort
}

export const sortList = (sortDir, sortField, sortName = '_sort') => {
  return async (dispatch, getState) => {
    sortDir = parseInt(sortDir)
    const router = getState().router
    const search = new URLSearchParams(router.location.search)

    if (isNaN(sortDir)) {
      search.delete(sortName)
    } else if (sortDir === -1) {
      search.set(sortName, `-${sortField}`)
    } else if (sortDir === 1) {
      search.set(sortName, `${sortField}`)
    }

    dispatch(replace({pathname: router.location.pathname, search: `?${search.toString()}`}))
  }
}

// TODO add 100 ms interval to prevent useless requests?
export const filterList = (filterValue, filterField) => {
  return async (dispatch, getState) => {
    const router = getState().router
    const search = new URLSearchParams(router.location.search)
    if (filterValue && filterValue.length > 0) {
      search.set(filterField, filterValue)
    } else {
      search.delete(filterField)
    }
    // Enable search on subsequent pages
    search.delete('_page')
    dispatch(replace({pathname: router.location.pathname, search: `?${search.toString()}`}))
  }
}

export const goToPage = (page) => {
  return async (dispatch, getState) => {
    // If we are passed a regular string, just wrap as pathname
    if (typeof page === 'string' || page instanceof String) {
      dispatch(push({pathname: page}))
      return
    }

    // If we don't have a search, just push page and hope for the best
    if (!page.search) {
      dispatch(push(page))
      return
    }

    // If search is a string should be okay
    if (typeof page.search === 'string' || page.search instanceof String) {
      dispatch(push(page))
      return
    }
    
    // If search is a URLSearchParams parse to string
    if (page.search.constructor.name === 'URLSearchParams') {
      dispatch(push({pathname: page.pathname, search: `?${page.search.toString()}`}))
      return
    } 
    
    // Hope search is an parseable object and serialize
    try {
      const search = new URLSearchParams(page.search)
      dispatch(push({pathname: page.pathname, search: `?${search.toString()}`}))
      return
    } catch (e) {
      console.error('Unable to parse page object:\n')
      console.error(e)
    }
  }
}
