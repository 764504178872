import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import CoreLayout from 'react-material-site/lib/layouts/CoreLayout'
import { sortList, sortParse, filterList } from '../../modules/router'

import {
  Dialog,
  DialogTitle,
  DialogContent
} from 'react-material-site/lib/components/Dialog'
import Fab from 'react-material-site/lib/components/Fab'
import IconButton from 'react-material-site/lib/components/IconButton'
import Pager from 'react-material-site/lib/components/Pager'

import {
  fetchUserList, 
  fetchUserItem, 
  createUser, 
  updateUser, 
  changeUserForm, 
  logoutUser,
  accountChange,
  userType,
  changeUserItem,
  fetchUserHistory,
  cleanChannelGroupPubnub,
  downloadUsers,
  resetError
} from '../../modules/user'
import Table from '../../components/Table'
import User from '../../forms/User'

import './Users.scss'

// Local Components
import Modal from '../../components/Modal'

class Users extends Component {
  state = { 
    open: false,
    flag: false,
    history: false,
    user: -100000,
    newUser:false,
    prevHelperMessage: { error: false }
  }

  componentWillMount() {
    this.props.fetchUserList()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.router.location.search !== nextProps.router.location.search) {
      this.props.fetchUserList()
    }
  }

  render() {
    const props = this.props
    const search = new URLSearchParams(props.router.location.search)
    const sort = sortParse(search.get('_sort'))
    const newUser = !props.user.item.id
    return (
      <CoreLayout {...props}>
        <div style={{textAlign: 'center', margin: '20px 10px', minHeight: '900px', position: 'relative'}}>
          
          <Fab 
            icon='add'
            label='New User'
            onClick={async () => {
              await props.changeUserItem({
                status: 'active',
                groups: ['user'],
                expires_on: moment().add(1, 'years').format('YYYY-MM-DD')
              })

              this.setState({ open: true, newUser: true })
            }}
            style={{ zIndex: '2', marginRight: '20px'}}
          />
          
          <Fab 
            icon='add'
            label='New Demo'
            onClick={async () => {
              await props.changeUserItem({
                status: 'active',
                groups: ['demo'],
                expires_on: moment().add(10, 'days').format('YYYY-MM-DD')
              })

              this.setState({ open: true,  newUser: true })
            }}
            style={{ zIndex: '2', marginRight: '20px' }}
          />

          <Fab 
            //icon='add'
            label='Reset History'
            onClick={async () => {
              await props.cleanChannelGroupPubnub()
            }}
            style={{ zIndex: '2', marginRight: '20px', bottom: '8px' }}
          />

          <Fab 
            //icon='add'
            label='Download Users'
            onClick={() => {
              props.downloadUsers()
            }}
            style={{ zIndex: '2', marginRight: '20px', bottom: '8px' }}
          />

          <Pager
            {...props.user.pager}
            parseUrl={(url) => url ? url.replace(process.env.API_URL, '') : ''}
          />

          <Table 
            wrap
            fields={{              
              email: {label: 'Email', filter: true},
              first_name: {label: 'First Name', style: { width: '85px' }, filter: true},
              last_name: {label: 'Last Name', style: { width: '85px' }, filter: true},
              company: {label: 'Company', filter: true},
              company_id: {label: 'Company ID', filter: true},
              contact_id: {label: 'Contact ID', filter: true},
              groups: {label: 'Type', style: { width: '75px' }, filter: true, render: value => value.length > 0 ? value[0] : ''},
              status: {label: 'Status', render: value => value.toString()},
              expires_on: {label: 'Expires On', render: value => moment(value).format('MMM Do, YYYY')},
              created_at: {label: 'Created On', render: value => moment(value).format('MMM Do, YYYY')},
              updated_at: {label: 'Updated On', render: value => moment(value).format('MMM Do, YYYY')},
              logout_user: {label: <span>Logout&nbsp;User</span>, render: (value, row) => (
                <IconButton icon='exit_to_app' onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  await props.fetchUserItem(row)
                  this.setState({ flag: true })
                }} />
              )},
              view_history: {label: <span>View&nbsp;History</span>, render: (value, row) => (
                <IconButton icon='list' onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  await props.fetchUserHistory(row.id)
                  this.setState({ history: true })
                }} />
              )}
            }}
            
            filterChange={props.filterList}
            search={search}
            
            rowClick={async (row) => {
              await props.fetchUserItem(row)
              this.setState({ open: true })
            }}

            sortChange={props.sortList}
            sortField={sort.field}
            sortDir={sort.direction}
            
            data={props.user.list}
          />
          <Pager
            {...props.user.pager}
            parseUrl={(url) => url ? url.replace(process.env.API_URL, '') : ''}
          />
        </div>

        <Dialog
          open={this.state.open}
          onClose={() => {
            this.setState({ open: false, newUser: false })
          }}
        >
          <DialogTitle>{newUser ? 'New User' : 'Edit User'}</DialogTitle>
          <DialogContent style={{width: '400px', padding: 0}}>
            <User
              data={props.user.item}
              helperMessage={props.user.helperMessage}
              formChange={props.changeUserForm}
              resetError={props.resetError}
              accountChange={props.accountChange}
              channelChange={props.channelChange}
              userType={props.userType}
              accountEnable={this.props.accountEnable}
              newUser={this.state.newUser}
              formSubmit={async (evt) => {
                evt.preventDefault()
                if (newUser) {
                  await props.createUser()
                } else {
                  await props.updateUser()
                }

                this.setState({ open: false, newUser: false })
              }}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.flag}
          onClose={() => {
            this.setState({ flag: false })
          }}
        >
          <DialogTitle>Logout User</DialogTitle>
          <DialogContent style={{width: '300px', padding: 0}}>
          <Modal
            submit={() => {
              this.setState({ flag: false })
            }}
            logoutUser={props.logoutUser}
            data={props.user.item}
          />
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.history}
          onClose={() => {
            this.setState({ history: false })
          }}
        >
          <DialogTitle>User History</DialogTitle>
          <DialogContent style={{padding: '20px 60px'}}>
            {props.user.history && props.user.history.length > 0 && props.user.history.map((message, index) => (
              <div key={index}>
                {message.entry.uuid && 
                  <div style={{padding: '10px'}}>
                    <div style={{fontWeight: 800}}>
                      {moment.unix(Math.floor(message.timetoken / 10000000)).format('ddd, MMM Do, YYYY hh:mm A')}
                      <span style={{margin: '0px 10px'}}>-</span>({message.entry.action})
                    </div>
                    <div>{message.entry.os}, {message.entry.browser}, {message.entry.ip}</div>
                    <div style={{fontSize: '12px'}}>Identifier: {message.entry.uuid}</div>
                    <div style={{borderBottom: '1px solid lightgrey'}} />
                  </div>}

                {!message.entry.uuid && message.entry.action === 'forced logout' && 
                  <div style={{padding: '10px'}}>
                    <div style={{fontWeight: 800}}>
                      {moment.unix(Math.floor(message.timetoken / 10000000)).format('ddd, MMM Do, YYYY hh:mm A')}
                      <span style={{margin: '0px 10px'}}>-</span>({message.entry.action})
                      <div style={{borderBottom: '1px solid lightgrey'}} />
                    </div>
                  </div>}
              </div>
            ))}
            
            {(!props.user.history || props.user.history.length === 0) && 
              <div style={{padding: '20px'}}>There is no login history to show for this user.</div>}
          </DialogContent>
        </Dialog>

      </CoreLayout>
    )
  }
}

const mapDispatchToProps = {
  fetchUserList,
  fetchUserItem,
  createUser,
  updateUser,
  changeUserForm,
  changeUserItem,
  filterList,
  sortList,
  logoutUser,
  accountChange,
  userType,
  fetchUserHistory,
  cleanChannelGroupPubnub,
  downloadUsers,
  resetError
}

const mapStateToProps = state => ({
  router: state.router,
  user: state.user,
  form: state.form,
  accountEnable: state.user.userType,
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
