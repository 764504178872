//import { interfaceMessage } from 'react-material-site/lib/modules/ui'
import { getPagerHeaders } from '../utils/pager'
import { mergeQuery, translateQuery } from '../utils/query'
import { Get, Post, Put} from '../utils/xhr'
import Pubnub from 'pubnub'
import { binarySearch, validate, isValidEmail, isValidID, isValidContactID } from '../utils/helperFunctions'
import moment from 'moment'
// ------------------------------------
// Constants
// ------------------------------------
export const USERS_UPDATE = 'USERS_UPDATE'
export const USER_TYPE = 'USER_TYPE'
const userCsvHeaders = {
  email:	'Email',
  first_name: 'First Name',
  last_name:	'Last Name',
  company: 'Company',
  created_at:	'Created On',
  expires_on: 'Expires On',
  userType: 'User Type',
}
// ------------------------------------
// Account Protection -- Pubnub
// ------------------------------------
const SubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY
const PublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY

export const SINGLE_ACCESS = 'Single User'
export const MULTIPLE_ACCESS = 'Multiple Users'
// ------------------------------------
// Actions
// ------------------------------------
export const userUpdate = (params) => {
  return {
    type    : USERS_UPDATE,
    payload : params
  }
}

const defaultQuery = {
  _limit: 20,
  _sort: '-created_at'
}

export const fetchUserList = () => {
  return async (dispatch, getState) => {
    const query = translateQuery(
      mergeQuery(new URLSearchParams(getState().router.location.search), defaultQuery),
      ['email', 'first_name', 'last_name', 'company', 'company_id', 'contact_id', 'groups'],
      (value) => `${value}__contains`
    )
    
    Get('/users', query).then((response) => {
      dispatch(userUpdate({ pager: getPagerHeaders(response), list: response.body.data, total: response.body.total }))
    }).catch((error) => {
      console.error(error)
      //dispatch(interfaceMessage('API_ERROR'))
    })
  }
}

export const fetchUserItem = (params) => {
  return async (dispatch, getState) => {
    if (!params.id) {
      console.error('user :: fetchUserItem - Params does not contain an id')
      //dispatch(interfaceMessage('API_ERROR'))
      return
    }
    
    Get(`/users/${params.id}`).then((response) => {
      const item = response.body
      delete item.password
      delete item._salt
      delete item.settings

      dispatch(userUpdate({ item: item }))
    }).catch((error) => {
      console.error(error)
      //dispatch(interfaceMessage('API_ERROR'))
    })
  }
}

export const createUser = () => {
  return async (dispatch, getState) => {
    const item = getState().user.item
    Post(`/users`, item).then(() => {
      dispatch(userUpdate({ item: {} }))
      dispatch(fetchUserList())
    }).catch((error) => {
      console.error(error)
      //dispatch(interfaceMessage('API_ERROR'))
    })
  }
}

export const updateUser = () => {
  return async (dispatch, getState) => {
    const item = getState().user.item 
    if (!item.id) {
      console.error('user :: updateUser - Item does not contain an id')
      //dispatch(interfaceMessage('API_ERROR'))
      return
    }
    
    Put(`/users/${item.id}`, item).then(() => {
      dispatch(userUpdate({ item: {} }))
      dispatch(fetchUserList())
    }).catch((error) => {
      console.error(error)
      //dispatch(interfaceMessage('API_ERROR'))
    })
  }
}

export const changeUserItem = (item) => {
  return async (dispatch) => {
    dispatch(userUpdate({ item }))
  }
}

export const changeUserForm = (evt) => {
  return async (dispatch, getState) => {
    const newState = {}
    // console.log(evt.target.name, evt.target.value)
    if (evt.target.name === 'groups') {
      newState[evt.target.name] = [evt.target.value]
    } else {
      newState[evt.target.name] = evt.target.value
    }
    // console.log(newState, getState().user.item)

    const item = Object.assign(
      {},
      getState().user.item,
      newState
    )
    
    const isValidated = validate(evt.target.value, item.groups[0] === 'admin' ? 10 : 8)
    if ((evt.target.name === 'password' && isValidated.all && evt.target.value.length > 0) ||
        (evt.target.name === 'email' && !isValidEmail(evt.target.value)) ||
        (evt.target.name === 'company_id' && !isValidID({id: evt.target.value, minLen: 10, companyId: true})) ||
        (evt.target.name === 'contact_id' && !isValidContactID({id: evt.target.value, minLen: 6}))
        )  {
        dispatch(userUpdate({ 
          helperMessage: {
            header1: {
              message: 'Invalid email',
              state: !isValidEmail(getState().user.item.email) || evt.target.name === 'email' ? !isValidEmail(evt.target.value) : false
            },
            header2: {
              message:'Password must have the following:',
              state:  evt.target.name === 'password' ?  isValidated.all : false
            },
            header3: {
              message: 'Company ID must of length 10 and only contain numbers',
              state: !isValidID({id: evt.target.value, minLen: 10, companyId: true}) && evt.target.name === 'company_id',
            },
            header4: {
              message: 'Contact ID must min. length of 6 and only contain numbers',
              state: !isValidContactID({id: evt.target.value, minLen: 6}) && evt.target.name === 'contact_id',
            },
            passwordLength: {
              message: `1. Must have a length of ${item.groups[0] === 'admin' ? 10 : 8}`,
              state:  evt.target.name === 'password' ? isValidated.passwordLength : false
            },
            passwordUppercase: {
              message: '2. Must have an upper case letter',
              state:  evt.target.name === 'password' ? isValidated.isUpperCase : false
            },
            passwordSymbol: {
              message: '3. Must have a symbol',
              state:  evt.target.name === 'password' ? isValidated.hasSymbol : false
            },
            passwordNumber: {
              message: '4. Must have a number',
              state:  evt.target.name === 'password' ? isValidated.hasNumber : false
            },
            error: true
          }
        }))
    } else {
      dispatch(userUpdate({ helperMessage: {error: false} }))
    }
    dispatch(userUpdate({ item: item }))
  }
}

export const resetError = () => {
  return async (dispatch) => dispatch(userUpdate({ helperMessage: {error: false} }))
}

export const fetchUserHistory = (id) => {
  return async (dispatch) => {
    id = `${id}_${process.env.REACT_APP_ENV}`
    const pubnub = new Pubnub({
      subscribeKey: SubscribeKey,
      publishKey: PublishKey,
    })
    const history = await pubnub.history({
      channel: id,
      count: 20,
      reverse: true
    })
    
    const messages = history.messages.sort((a,b) => b.timetoken - a.timetoken)

    dispatch(userUpdate({ history: messages }))
  }
}

// *********** Might need it in the future **************
// ******************************************************
export const cleanChannelGroupPubnub = () => {
  return async (disptach, getState) => {
    let channelGroups = process.env.REACT_APP_PUBNUB_CHANNEL_GROUP.split(',')
    let ans = window.confirm('You are removing all users from all channels. Are you sure?')
    if (ans) {
      const pubnub = new Pubnub({
        subscribeKey: SubscribeKey
      })
      for (let i = 0; i < channelGroups.length; i++) {
        let channel = channelGroups[i]
        pubnub.channelGroups.deleteGroup({
          channelGroup: channel
        }, function(status, res) {
          // console.log(status)
        })
      }
    }
  }
}

export const logoutUser = (id) => {
  return async (disptach, getState) => {
    id = `${id}_${process.env.REACT_APP_ENV}`
    const pubnub = new Pubnub({
      subscribeKey: SubscribeKey,
      publishKey: PublishKey,
    })
    
    const params = {
      message: {
        action: 'forced logout'
      },
      channel: id,
      storeInHistory: true
    }
    console.warn('Forcing logout :: ',params)
    await pubnub.signal(params)
  }
}

const checkUserPubnub = async (userID, channel) => {
    if (!userID) {
      // alert('User ID not found!')
      return 
    }
    let userChannels = false
    const pubnub = new Pubnub({
      subscribeKey: SubscribeKey
    })
    pubnub.channelGroups.listChannels({
      channelGroup: channel
    }, function(status, res) {
      // console.log(res, channel)
      userChannels = res.channels
    })
    let promise = new Promise((resolve, reject) => {
      let validUserPrescence = ''
      setTimeout(() => {
        if (!userChannels) {
          validUserPrescence = false
        } else {
          validUserPrescence = binarySearch(userChannels, userID, 0, userChannels.length - 1)
        }
        if (!validUserPrescence) {
          resolve(false)
        } else {
          resolve(true)
        }
      }, 300)
    })
    let isValid =  await promise
    return isValid
  }


export const changeUserTypePubNub = (userID, userType, channel) => {
  // console.log(userID, userType, channel)
  if (userType === MULTIPLE_ACCESS) {
    const pubnub = new Pubnub({
      subscribeKey: SubscribeKey,
    })
  
    pubnub.channelGroups.addChannels({ 
      uuid: userID,
      channels:[userID],
      channelGroup: channel
    },function(status, response) {
        // console.log(status, response, 'ADD CHANNEL', userID, channel, )
      })
  } else if (userType === SINGLE_ACCESS) {
    const pubnub = new Pubnub({
      subscribeKey: SubscribeKey,
    })
    // console.log(channel)
    pubnub.channelGroups.removeChannels({
      channels:[userID],
      channelGroup: channel
    }, function (status) {
      if (status.error) {
        // error = true
          // console.log("operation failed w/ error:", status);
      } else {
        // error = false
          // console.log("operation done! REMOVE CHANNEL")
      }
  })
  }
}

export const accountChange = (userID, optionType) => {
  return async (dispatch, getState) => {
    if (!userID) {
      // alert('User ID not found!')
      return 
    }
    // 111-------------------------------------------------------
    // userID = `${userID}_${process.env.NODE_ENV}`
    // console.log(userID)
    let channels = process.env.REACT_APP_PUBNUB_CHANNEL_GROUP_MULTIPLE_ACCESS.split(',')
    if (optionType) {
      for (let i in channels) {
        let channel = channels[i]
        changeUserTypePubNub(userID, MULTIPLE_ACCESS, channel)
      }
    } else if (!optionType) {
      for (let i in channels) {
        let channel = channels[i]
        changeUserTypePubNub(userID, SINGLE_ACCESS, channel)
      }
    }
  }
}

export const userType = (userID) => {
  return async (dispatch, getState) => {
    //------------------------------------------------------
    // userID = `${userID}_${process.env.NODE_ENV}`
    // console.log(userID)
    let channels = process.env.REACT_APP_PUBNUB_CHANNEL_GROUP_MULTIPLE_ACCESS.split(',')
    for (let i in channels) {
      let channel = channels[i]
      let multipleUser = checkUserPubnub(userID, channel)
      multipleUser.then(res => {
        if(res) {
          dispatch(userUpdate({ userType: true }))
        } else {
          dispatch(userUpdate({ userType: false }))
        }
      })  
    }
  }
}

const getUserHistory = async (id) => {
  id = `${id}_${process.env.REACT_APP_ENV}`
  const pubnub = new Pubnub({
    subscribeKey: SubscribeKey,
    publishKey: PublishKey,
  })
  const history = await pubnub.history({
    channel: id,
    count: 100,
    reverse: true
  })
  return history.messages.sort((a,b) => b.timetoken - a.timetoken)
}

export const downloadUsers = () => {
  return async (dispatch, getState) => {
    const users = await Promise.all(getState().user.list.map(async (u) => {
      const logData = await getUserHistory(u.id)
      const userData = {
        email: u.email,
        first_name: u.first_name,
        last_name: u.last_name,
        company: !u.company ? '' : u.company,
        created_at: u.created_at,
        expires_on: u.expires_on,
        userType: u.groups[0],
      }
      logData.forEach((message, index) => {
        if (message.entry.uuid) {
          userData[`message_${index}`] = (`Date:${moment.unix(Math.floor(message.timetoken / 10000000)).format('ddd|MMM-Do-YYYY|hh:mmA')}----OS:${message.entry.os}----IP:${message.entry.ip}----Action:${message.entry.action}`)
          userCsvHeaders[`message_${index}`] = `Message ${index}`
        }
      })
      return userData
    }))
  users.unshift(userCsvHeaders)
  const csv = convertToCSV(JSON.stringify(users))
  createDownloadLink('user_exports.csv', csv)
  }
}

const createDownloadLink = (exportedFilename, csv) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilename)
  } else {
    const link = document.createElement("a")
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute("href", url)
        link.setAttribute("download", exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
  }
}

const convertToCSV = (data) => {
  let str = ''
  const arr = typeof data != 'object' ? JSON.parse(data) : data
  for (let i = 0; i < arr.length; i++) {
    let line = ''
    for (let index in arr[i]) {
        if (line !== '') line += ','
        line += arr[i][index]
    }
    str += line + '\r\n'
  }
  return str
}

export const actions = {
  fetchUserList,
  fetchUserItem,
  logoutUser
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [USERS_UPDATE] : (state, action) => Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  pager: {},
  list: [],
  item: {},
  history: [],
  userType: '',
  helperMessage: {error: false}
}

export default function userReducer (state = initialState, action) {  
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
