import React from 'react'
import { connect } from 'react-redux'

import CoreLayout from 'react-material-site/lib/layouts/CoreLayout'
import Center from 'react-material-site/lib/components/Center'
import { TextField } from '@rmwc/textfield'
import { Typography } from '@rmwc/typography'
import { Card, CardActions, CardActionButtons, CardActionButton } from 'react-material-site/lib/components/Card'

import { loginChange, loginSubmit, loginKeyChange } from '../../modules/auth'

import './Login.scss'

export const Login = (props) => (
  <CoreLayout {...props}>
    <Center style={{width: '350px'}}>
      <Card style={{ padding: '1rem' }}>
        <Typography tag='div' use='headline6'>Login</Typography>
        <div style={{ padding: '1rem 0 0 0' }}>
          <TextField
            label='Email'
            name='login'
            onChange={props.loginChange}
            value={props.auth.form.login}
            style={{ width: '100%' }}
            onKeyPress={(e) => (e.key === 'Enter') && props.loginSubmit()}
          />
          <TextField 
            label='Password'
            type='password'
            name='password'
            onChange={props.loginChange}
            value={props.auth.form.password}
            style={{ width: '100%' }}
            onKeyPress={(e) => (e.key === 'Enter') && props.loginSubmit()}
          />
        </div>
        <CardActions style={{marginLeft: 'auto', paddingRight: 0}}>
          <CardActionButtons>
            <CardActionButton raised theme='secondaryBg' onClick={props.loginSubmit}>
              Login
            </CardActionButton>
          </CardActionButtons>
        </CardActions>
      </Card>
    </Center>
  </CoreLayout>
)

const mapDispatchToProps = {
  loginChange,
  loginSubmit
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
